import React from 'react';
import {Stack} from "@mui/material";
import {Img} from "react-image";

const HowItWorks = () => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'top',
                alignItems: 'center',
                height: '100vh',
                width: '100%'
            }}
        >
            <h2>How it works</h2>
            <br></br>

            <Stack direction="row" spacing={1} justifyContent="flex-start">
                <span className="circle">1</span>
                <span className="Span-text">Actors of the Application: Home Owners & Service Providers.</span>
            </Stack>


            <span className="Span-text-desc">Home owners need work done fast.
                    They download the hometask.ai mobile app and us it to create an AI home project. The project aggregates videos, pictures
                    and sound and the user uploads the project to the cloud. AI and ML is used to categorize and process the resources in the project.
                 </span>
            <Img src='http://hometask.io/images/ACTORS.png' alt="help"/>


            <Stack direction="row" spacing={1} justifyContent="flex-start" alignItems="flex-start">
                <span className="circle">2</span>
                <span className="Span-text">Geographical publish and search projects.</span>
            </Stack>
            <span className="Span-text-desc">Geo fencing is used to match needs nad services. Home owners and service providers can geo graphically publish and search for projects so that
                cost is minimized and relationships can be established to enable success.
                 </span>
            <Img src='https://hometask.io/images/GEO-FENCING.png' alt="help"/>

            <Stack direction="row" spacing={1} justifyContent="flex-start" alignItems="flex-start">
                <span className="circle">3</span>
                <span className="Span-text">Home Owners need home work done related to their home</span>
            </Stack>
            <span className="Span-text-desc">Home owners can create projects and publish them to the cloud. Videos, pictures and sound resources are analyzed in the cloud using AI and ML processing.
                 </span>
            <Img src='http://hometask.io/images/HOMEOWNER.png' alt="help"/>

            <Stack direction="row" spacing={1} justifyContent="flex-start" alignItems="flex-start">
                <span className="circle">4</span>
                <span className="Span-text">Service providers search geographically for jobs and projects </span>
            </Stack>
            <span className="Span-text-desc">Service Providers can search categorized projects in their desired geographics.
                 </span>
            <Img src='http://hometask.io/images/CONTRACTOR.png' alt="help"/>

            <Stack direction="row" spacing={1} justifyContent="flex-start" alignItems="flex-start">
                <span className="circle">5</span>
                <span
                    className="Span-text">Home Owners & Service Providers can communicate via text, chat and email</span>
            </Stack>
            <span className="Span-text-desc">Users can communicate using chat software, mobile text and email to make agreements to complete project.
                 </span>
            <Img src='http://hometask.io/images/COMMUNICATION.png' alt="help"/>

            <Stack direction="row" spacing={1} justifyContent="flex-start" alignItems="flex-start">
                <span className="circle">6</span>
                <span className="Span-text">Home Project is complete portfolios are created.</span>
            </Stack>
            <span className="Span-text-desc">Completion of project results in portfolios published for all parties.
                 </span>
            <Img src='http://hometask.io/images/COMPLETED.png' alt="help"/>

            <Stack direction="row" spacing={1} justifyContent="flex-start" alignItems="flex-start">
                <span className="circle">7</span>
                <span className="Span-text">Sentiment of home owners and service providers.</span>
            </Stack>
            <span className="Span-text-desc">Cloud processing captures sentiments pertaining to projects from both parties.
                 </span>
            <Img src='http://hometask.io/images/SENTIMENT.png' alt="help"/>

        </div>
    );
};

export default HowItWorks;

