import React from 'react';
import logo from './images/hometask-final.png';
import './App.css';
import Navbar from "./components/NavBar";
import {BrowserRouter as Router, Link, Route, Routes} from "react-router-dom";
import Home from './pages';
import Ui from './pages/Ui';
import Api from './pages/Api';
import HowItWorks from './pages/HowItWorks';
import Db from "./pages/DB";
import Architecture from "./pages/architecture";
import Login from "./pages/login";
import Contact from "./pages/Contact";
import {NavLink} from "./components/NavBar/NavbarElements";
import {Grid, Stack} from "@mui/material";
import Marketing from './pages/Marketing';
import useScript from "./components/scripts/useScript2";

function App() {

    const status = useScript('https://hometask.io/prod-aws-connect.js');

    if (status === 'loading') {
        return <div>Loading...</div>;
    }

    if (status === 'error') {
        return <div>Error loading script</div>;
    }

    return (
        <div className="App">
            <header className="App-header">
                <Router>

                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    > <NavLink to="/">
                        <img src={logo} width="240px" height="60px" alt="logo"/>
                    </NavLink>
                        <NavLink to="/login">
                            <span className="Span-right">Login</span>
                        </NavLink>
                    </Grid>

                    <Navbar/>
                    <Routes>
                        <Route path='/' element={<Home/>}/>
                        <Route path="/howitworks" element={<HowItWorks/>}/>
                        <Route path="/architecture" element={<Architecture/>}/>
                        <Route path="/ui" element={<Ui/>}/>
                        <Route path="/db" element={<Db/>}/>
                        <Route path="/api" element={<Api/>}/>
                        <Route path="/contact" element={<Contact/>}/>
                        <Route path="/marketing" element={<Marketing/>}/>
                        <Route path="/login" element={<Login/>}/>
                    </Routes>
                </Router>
            </header>
        </div>
    );
}

export default App;
