import React from 'react';
import {Stack} from "@mui/material";
import {Img} from "react-image";

const Architecture = () => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'top',
                alignItems: 'center',
                height: '100vh',
                width: '100%'
            }}
        >
            <h2>High Level Architecture</h2>
            <br></br>
            <Stack direction="row" spacing={1} justifyContent="flex-start" alignItems="flex-start">
                <span className="circle">1</span>
                <span className="Span-text">AWS S3 Architecture.</span>
            </Stack>
            <span className="Span-text-desc">Videos are captured using a mobile device uploaded, contractors then search for projects in their preferred geolocation.
                 </span>
            <Img src='http://hometask.io/images/hometask-S3-architecture.png' alt="help"/>


            <Stack direction="row" spacing={1} justifyContent="flex-start" alignItems="flex-start">
                <span className="circle">2</span>
                <span className="Span-text">Complete AWS Serverless Architecture.</span>
            </Stack>
            <span className="Span-text-desc">Videos are uploaded and processed in the cloud using innovative AI/ML tools to enhance categorization, searching and geolocation.
                 </span>
            <Img src='http://hometask.io/images/ARCHITECTURE-DETAIL.png' alt="help"/>





        </div>
    );
};

export default Architecture;

