import React from 'react';
import {Stack} from "@mui/material";
import {Img} from "react-image";


const Contact = () => {
    return (
        <div
            style={{
                display: 'flex',
                color: '#5294CF',
                flexDirection: 'column',
                justifyContent: 'top',
                alignItems: 'center',
                height: '100vh',
                width: '100%',
                backgroundImage: `url("http://hometask.io/images/home-page-hometaskai.jpg")`
            }}
        >

            <br></br>
            <br></br>
            <br></br>
            <Stack direction="column" spacing={1} justifyContent="flex-start">
                <span className="Span-home-title">Contact Us for home innovativation</span>
                <br></br>  <ul>
                    <li>
                        <span className="Span-home">Contact Hometask Support: +1 855-681-5813</span>
                    </li>
                    <li>
                        <span className="Span-home">Please use the chat app on bottom right</span>
                    </li>

            </ul>

            </Stack>


        </div>
    );
};

export default Contact;

