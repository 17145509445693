import React, {useState} from 'react';
import {Amplify, Auth} from "aws-amplify";
import {Authenticator} from '@aws-amplify/ui-react';
import {Grid} from "@mui/material";
import {NavLink} from "../components/NavBar/NavbarElements";
import logo from "../images/hometask-final.png";

//Amplify.configure({ Auth: { identityPoolId: 'us-east-1:3124fc16-ec5f-4a8d-b8cf-41c2606cae2e', region: 'us-east-1', userPoolId: 'us-east-1_F4aitl420', userPoolWebClientId: '6q2ltq2enksksheucrk8mooimc' } });


const Login = () => {
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'Column',
                justifyContent: 'Middle',
                alignContent: 'space-around',
                alignItems: 'flex-center',
                height: '100vh',
                width: "100%"
            }}
        >

            <Grid
                container
                direction="column"
                justifyContent="space-between"
                alignItems="center"
            >
                <Authenticator>
                    {({ signOut, user }) => (
                        <div>
                            <p>Welcome {user.username}</p>
                            <button onClick={signOut}>Sign out</button>
                        </div>
                    )}
                </Authenticator>
            </Grid>

        </div>
    );
};

export default Login;
