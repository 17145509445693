import { useState, ChangeEvent } from 'react'
import axios from 'axios'

type UploadStatus = "idle" | "uploading" | "success" | "error";

export default function FileUploader() {
    const [file, setFile] = useState<File | null>(null);
    const [status, setStatus] = useState<UploadStatus>("idle");
    const [uploadProgress, setUploadProgress] = useState(0);

    function handleFileChange (e: ChangeEvent<HTMLInputElement>) {
        if (e.target.files) {
            setFile(e.target.files[0]);
            console.log(e.target.files[0]);
        }
    }

    async function handleFileUpload() {

        if(!file) return;
        setStatus("uploading");
        setUploadProgress(0);
        const formData = new FormData();
        formData.append("file", file);

        try {
            await axios.post("https://httpbin.org/post", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    },
                    onUploadProgress: (progressEvent) => {
                        const progress = progressEvent.total ? Math.round((progressEvent.loaded * 100) / progressEvent.total) : 0;
                        setUploadProgress(progress);
                    }
                }
            );
            setStatus("success");
            setUploadProgress(100);
        } catch (error) {
            setStatus("error");
            setUploadProgress(0);
            console.error("Error uploading file: ", error);
        }
    }

    return (
        <div>
            <input type="file" onChange={handleFileChange}/>
            {file && (<p>File Name: {file.name} File Type: {file.type} File Size: {file.size} bytes
            </p>)
            }
            {file && status !== "pending" && <button onClick={handleFileUpload}>Upload</button>}
            {file && status === "loading" && (
                      <div className="space-y-2">
                          <div className="h-2.5 w-full rounded-full bg-gray-200">
                            <div className="h-2.5 bg-blue-600 rounded-full transition-all duration-300" style={{ width: `${uploadProgress}%`}}/>
                          </div>
                      </div>
            )
            }

            {status === "success" && <p>File uploaded successfully</p>}
            {status === "error" && <p>File upload failed. Please try again.</p>}

        </div>
    );
}
