import React from 'react';
import {Stack} from "@mui/material";
import {Img} from "react-image";

const Db = () => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'top',
                alignItems: 'center',
                height: '100vh',
                width: '100%'
            }}
        >
            <h2>DynamoDB single table design</h2>
            <br></br>

            <span className="Span-text-desc">DynamoDB provides fast consistent access to entities
                 </span>
            <br></br>
            <Stack direction="row" spacing={1} justifyContent="flex-start" alignItems="flex-start">
                <Img src='http://hometask.io/images/db-1.png' alt="help" class="db-image"/>
            </Stack>

            <Stack direction="row" spacing={1} justifyContent="flex-start" alignItems="flex-start">
                <Img src='http://hometask.io/images/db-2.png' alt="help" class="db-image"/>
            </Stack>
            <br></br>
            <span className="Span-text-desc">Access patterns in DynamoDB are described in Animated GIF below.
                 </span>
            <br></br>
            <Stack direction="row" spacing={1} justifyContent="flex-start" alignItems="flex-start">
                <Img src='http://hometask.io/images/DynamoDBWebTEMP.gif' alt="help" class="db-image"/>
            </Stack>

        </div>
    );
};

export default Db;
