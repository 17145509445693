import React from 'react';
import {Stack} from "@mui/material";
import {Img} from "react-image";

import { useState } from 'react'
import reactLogo from '../assets/react.svg'
import '../App.css'
import FileUploader from '../components/FileUploader'


const Api = () => {
    return (
        <div
            style={{
                display: 'flex',
                color: '#5294CF',
                flexDirection: 'column',
                justifyContent: 'top',
                alignItems: 'center',
                height: '100vh',
                width: '100%',
                backgroundImage: `url("http://hometask.io/images/home-page-hometaskai.jpg")`
            }}
        >

            <br></br>
            <br></br>
            <br></br>
            <Stack direction="column" spacing={1} justifyContent="flex-start">
                <span className="Span-api-title">Upload your home project video and analyze result</span>
                <br></br>
                <ul>
                    <li>
                        <span className="Span-home">Hometask AI API Demo</span>
                    </li>
                </ul>
                <div>
                    <FileUploader/>
                </div>
            </Stack>
        </div>
    );
};

export default Api;

